import React from "react";
import ReactDOM from "react-dom";
import GSCMaterial from "@gsc/material";
import Presentation from "./Presentation";
import * as serviceWorker from "./serviceWorker";
import "@gsc/material/ProximaNovaFontFace.css";

const App = () => (
    <GSCMaterial>
        <Presentation />
    </GSCMaterial>
);

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
