import React from "react";
import { Slide } from "spectacle";
import * as Space from "react-spaces";
import { screen } from "../config";

const QuestionSlide = ({ question }) => {
    return (
        <Slide>
            <Space.ViewPort>
                <Space.Fixed width={screen.width} height={screen.height}>
                    <div
                        style={{
                            position: "relative",
                            paddingBottom: "56.25%",
                            paddingTop: 0,
                            height: 0,
                            overflow: "hidden",
                        }}
                    >
                        <iframe
                            title={question.label}
                            sandbox="allow-scripts allow-same-origin allow-presentation"
                            allowFullScreen={true}
                            allowtransparency="true"
                            frameBorder="0"
                            height={screen.height}
                            src={`https://www.mentimeter.com/embed/${question.id}`}
                            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                            width={screen.width}
                        ></iframe>
                    </div>
                </Space.Fixed>
            </Space.ViewPort>
        </Slide>
    );
};

export default QuestionSlide;
