import React from "react";
import { Deck } from "spectacle";
import SelectionList from "./components/SelectionList";
import QuestionSlide from "./components/QuestionSlide";
import createPersistedState from "use-persisted-state";
import { deckConfig } from "./config";

const useQuestionState = createPersistedState("gscMentiQuestions");
const useAutoplayState = createPersistedState("gscMentiAutoplayTime");

const Presentation = () => {
    const [started, setStarted] = React.useState(false);
    const [autoplayDuration, setAutoplayDuration] = useAutoplayState(15000);
    const initialQuestionMap = [
        {
            label: "EDI - Three Words",
            id: "3fcaf4170de5816c967088e7d3555139/9e6dda5b6492",
            include: true,
        },
        {
            label: "EDI - Key Takeaway",
            id: "3fcaf4170de5816c967088e7d3555139/b48c38481c4f",
            include: true,
        },
        {
            label: "EDI - Do Differently",
            id: "3fcaf4170de5816c967088e7d3555139/c1665c416475",
            include: true,
        },
        {
            label: "I&C - Three Words",
            id: "00ccc604d3801107c7ddee37f897d228/98ddbe19c81d",
            include: true,
        },
    ];
    const [questionMap, setQuestionMap] = useQuestionState(initialQuestionMap);

    return started ? (
        <Deck {...deckConfig} autoplayDuration={autoplayDuration}>
            {questionMap
                .filter(question => question.include)
                .map(question => (
                    <QuestionSlide key={question.id} question={question} />
                ))}
        </Deck>
    ) : (
        <SelectionList
            setStarted={setStarted}
            autoplayDuration={autoplayDuration}
            setAutoplayDuration={setAutoplayDuration}
            questionMap={questionMap}
            setQuestionMap={setQuestionMap}
        />
    );
};

export default Presentation;
