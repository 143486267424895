import React from "react";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LiveClock from "./LiveClock";
import gscLogoInverse from "../assets/gsclogo-inverse.png";

const useStyles = makeStyles(theme => ({
    appBar: {
        top: "auto",
        bottom: 0,
        padding: theme.spacing(1),
        minHeight: "10%",
        justifyContent: "center",
    },
    title: {
        paddingLeft: theme.spacing(3),
    },
}));

const GSCNavBar = () => {
    const classes = useStyles();
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12} md={6} container alignItems="center">
                        <img src={gscLogoInverse} width="100px" alt="Glasgow Science Centre's logo" />
                        <Typography variant="h3" color="inherit" className={classes.title}>
                            GSC Connects
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} xl={1} container justify="flex-start">
                        <LiveClock />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default GSCNavBar;
