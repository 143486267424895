import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import format from "date-fns/format";
import useInterval from "../assets/useInterval";

const useStyles = makeStyles(theme => ({
    clock: {
        paddingRight: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "70%",
        },
    },
}));

const LiveClock = () => {
    const classes = useStyles();
    const [date, setDate] = useState(new Date());
    useInterval(() => setDate(new Date()), 1000);

    return (
        <Typography className={classes.clock} variant="h4">
            {format(date, "HH:mm:ss")}
        </Typography>
    );
};

export default LiveClock;
