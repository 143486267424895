import createTheme from "spectacle/lib/themes/default";

export const screen = { width: 1920, height: 1080 };

export const deckConfig = {
    autoplay: true,
    // autoplayDuration is configurable in Presentation.js
    autoplayLoop: true,
    autoplayOnStart: true,
    contentWidth: screen.width,
    contentHeight: screen.height,
    controls: false,
    progress: "none",
    showFullscreenControl: false,
    transition: [],
    transitionDuration: 1000,
    theme: createTheme({
        primary: "#30b7bb",
        secondary: "#f1f4f5",
        tertiary: "#2e2d44",
        quaternary: "#2e2d44",
    }),
    // This theme isn't explicitly used but is the easiest way to ensure the transitional background isn't bright yellow!
};
