import React from "react";
import {
    Grid,
    Typography,
    Button,
    TextField,
    InputAdornment,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import GSCNavBar from "./GSCNavBar";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    topLevel: {
        background: theme.gsc.offwhite,
        width: "100%",
        height: "100vh",
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(10),
    },
    autoplaySelector: {
        textDecoration: "none",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(4),
        marginTop: 0,
        marginBottom: 0,
    },
}));

const goFullScreen = async () => {
    try {
        await document.documentElement.requestFullscreen({ navigationUI: "hide" });
    } catch (err) {
        console.warn(`An error occurred while trying to switch into full-screen mode: ${err.message} (${err.name})`);
    }
};

const QuestionSelector = ({ questionMap, setQuestionMap }) => {
    const toggleInclude = selectedId => {
        const newMap = questionMap.reduce((accumulator, question) => {
            const modifiedQuestion =
                question.id === selectedId ? { ...question, include: !question.include } : question;
            return [...accumulator, modifiedQuestion];
        }, []);
        setQuestionMap(newMap);
    };
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Select questions to include results for:</FormLabel>
            <FormGroup>
                {questionMap.map(question => (
                    <FormControlLabel
                        key={question.id}
                        control={
                            <Checkbox
                                checked={question.include}
                                onChange={() => toggleInclude(question.id)}
                                value={question.id}
                            />
                        }
                        label={question.label}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

const AutoplayStarter = ({ setStarted, questionMap, autoplayDuration, setAutoplayDuration }) => {
    const classes = useStyles();
    const handleStart = () => {
        if (autoplayDuration !== 0 && questionMap.filter(question => question.include).length !== 0) {
            goFullScreen();
            setStarted(true);
        }
    };
    return (
        <Grid className={classes.autoplaySelector} item container direction="row" justify="center" alignItems="center">
            <TextField
                id="autoplayTime"
                label="Display each question for"
                className={classes.textField}
                value={autoplayDuration === 0 ? "" : autoplayDuration / 1000}
                InputProps={{
                    endAdornment: <InputAdornment position="end">seconds</InputAdornment>,
                }}
                onChange={event => setAutoplayDuration(event.target.value * 1000)}
                margin="normal"
                variant="outlined"
            />
            <Button variant="contained" color="secondary" size="large" onClick={handleStart}>
                <Typography variant="h6">Start</Typography>
            </Button>
        </Grid>
    );
};

const SelectionList = ({ setStarted, autoplayDuration, setAutoplayDuration, questionMap, setQuestionMap }) => {
    const classes = useStyles();
    return (
        <>
            <GSCNavBar />
            <Grid
                className={classes.topLevel}
                container
                spacing={0}
                direction="column"
                justify="center"
                alignItems="center"
            >
                <QuestionSelector questionMap={questionMap} setQuestionMap={setQuestionMap} />
                <AutoplayStarter
                    setStarted={setStarted}
                    questionMap={questionMap}
                    autoplayDuration={autoplayDuration}
                    setAutoplayDuration={setAutoplayDuration}
                />
                <Typography variant="body1">
                    To get back to this screen, refresh the whole page in your browser.
                </Typography>
            </Grid>
        </>
    );
};

export default SelectionList;
